import { BaseViewModel } from '../../../../common';
import { autoinject, bindable } from 'aurelia-framework';
import { customElement } from 'aurelia-templating';
import { getLogger } from 'aurelia-logging';
import {
  IAsyncEntity,
  IAsyncEntityManager,
  createFetchedEntity
} from '../../../../../types';
import {
  ICustomerManagerDetails,
  ICustomerManager,
  IBuid,
  AccessLevel
} from '../../../../../interfaces';
import { CustomerService, BuidService } from '../../../../../services';
import {
  emptyObject,
  isNone,
  hasAccessToBuid,
  userHasFeature
} from '../../../../../utility';
import { rootState } from '../../../../../reducers';
import './customermanagereditor.css';
import {
  customerManagerChange,
  IPendingUserTokenAndAccess
} from '../../../customermanagerReducer';
import { getSession } from '../../../../../config/sessionService';
import { NotificationService } from '../../../../../services/notificationService';
import { features } from '../../../../../config';

interface ICustomerManagerEditorState {
  customerManager: IAsyncEntity<Partial<ICustomerManagerDetails>>;
  buids: IAsyncEntity<IBuid[]>;
  pendingUserAccess: IAsyncEntity<IPendingUserTokenAndAccess>;
  isMobile: boolean;
}

const emptyAsyncEntityManager = IAsyncEntityManager.Create(
  createFetchedEntity(emptyObject as Partial<ICustomerManager>)
);

@autoinject()
@customElement('customermanager-editor')
export class CustomerManagerEditor extends BaseViewModel<
  ICustomerManagerEditorState
> {
  @bindable({ changeHandler: 'reattachMapState' }) customerId:
    | number
    | undefined;
  @bindable({ changeHandler: 'reattachMapState' }) persisted: Function;
  @bindable({ changeHandler: 'reattachMapState' }) cancelled: Function;

  nameIsLocked : boolean = true;
  canEditName: boolean = false;

  constructor(
    private customerService: CustomerService,
    private buidService: BuidService,
    private notificationService: NotificationService
  ) {
    super(getLogger('CustomerManagerEditor'));

    this.nameIsLocked = true;
    this.canEditName =  userHasFeature(
      getSession(),
      features.customerManagerEditName,
      AccessLevel.Write
    );
  }

  bind = () => this.reattachMapState();

  reattachMapState = () => this.attachMapState(this.mapState(this.customerId));  

  cancelChanges() {
    this.cancelled && this.cancelled();
  }

  async lookupCustomer(soldTo: string) {
    console.log('SAP lookup from soldTo: ' + soldTo);

    this.customerService.sapLookup(soldTo).then(r => {

      //If we got no name in return, return
      if (!r.name) {
        this.notificationService.notify({
          type: 'CUSTOM',
          level: 'error',
          text: 'Entered sold-to not found in YSAP',
          timestamp: new Date().toString(),
          acknowledged: false
        });  
        return;
      }

      //Update name only if field is unlocked or this is a create operation
      if ((!this.nameIsLocked || !this.customerId)) {          
        this.dispatchCustomerManagerChange('name', r.name);
      }

      if (!r.name) {
        this.notificationService.notify({
          type: 'CUSTOM',
          level: 'error',
          text: 'Unable to generate access tag (name), probably due to unknown business unit. You will have to enter it manually (<buid>.<customername>, example es.air1.customername).',
          timestamp: new Date().toString(),
          acknowledged: false
        });  
      }

      this.dispatchCustomerManagerChange('alias', r.alias);
      this.dispatchCustomerManagerChange('addressLine1', r.addressLine1 ?? '');
      this.dispatchCustomerManagerChange('addressLine2', r.addressLine2 ?? '');
      this.dispatchCustomerManagerChange('city', r.city ?? '');
      this.dispatchCustomerManagerChange('country', r.country ?? '');
      this.dispatchCustomerManagerChange('postcode', r.postcode?.toString() ?? '');
      this.dispatchCustomerManagerChange('buidId', r.buidId ?? 0);        
    });
}

  async saveChanges(
    customerManagerToSave: Partial<ICustomerManagerDetails> = emptyObject
  ) {
    const { customerId } = customerManagerToSave;
    let updatedCustomermanager: ICustomerManager | undefined;    

    if (isNone(customerId) || customerId === 0) {
       try {
        updatedCustomermanager = await this.customerService.addCustomerManagerAsync(
          customerManagerToSave
        );
          
        this.notificationService.notify({
          type: 'CUSTOM',
          level: 'info',
          text: 'Customer created, you will need to logout and back in to be able to access it.',
          timestamp: new Date().toString(),
          acknowledged: false
        });  
       }
       catch (e) {
          console.log(e['name']);
        if (e['name'] && e['name'][0] === 'UI_CustomerManager_ValidationErrors_NameAlreadyExists')  
            this.notificationService.notify({
              type: 'CUSTOM',
              level: 'error',
              text: 'This access tag (name) is in use by another customer',
              timestamp: new Date().toString(),
              acknowledged: false
            });  
          else
            this.notificationService.notify({
              type: 'CUSTOM',
              level: 'error',
              text: 'Error creating customer, ensure you used an access tag you have access to.',
              timestamp: new Date().toString(),
              acknowledged: false
            });
       }
    }
    else {
      try {
        updatedCustomermanager = await this.customerService.updateCustomerAsync(
          customerId,
          customerManagerToSave
          );

          this.notificationService.notify({
            type: 'CUSTOM',
            level: 'info',
            text: 'Customer updated, you might need to logout and back in to be able to access it.',
            timestamp: new Date().toString(),
            acknowledged: false
          });
      } catch (e) {
          this.notificationService.notify({
            type: 'CUSTOM',
            level: 'error',
            text: this.getErrorText(e),
            timestamp: new Date().toString(),
            acknowledged: false
          });
      }
    }
    this.persisted &&
      this.persisted({ customerManager: updatedCustomermanager });
  }

  getErrorText(error: any){
    if(error.name) {
      return error.name;
    } else
    return "UI_CustomerManager_Edit_SomethingWentWrong";
  }

  dispatchCustomerManagerChange = (property: string, value: string | number) =>
    this.dispatch(customerManagerChange({ property, value }));

  mapState = (customerId: number | undefined) => ({
    customermanager,
    device
  }: rootState): ICustomerManagerEditorState => {
    const { pendingCustomerManager } = customermanager;
    const customerFetcher = isNone(customerId)
      ? emptyAsyncEntityManager
      : this.customerService.getCustomerManagersById(customerId);

    const buidFetcher = this.buidService.getAllBuids();

    const responsibleBuid = buidFetcher
      .map(buids => buids.filter(b => b.buidId == getSession().currentUser.buidId))
      .getEntityOrUndefined()?.pop();

    const pendingCustomerFetcher = pendingCustomerManager
      ? customerFetcher.map(customer => ({
          ...customer,
          ...pendingCustomerManager,          
        }))
      : customerFetcher;
    
    const userAccessFetcher =
      device.screenSize === 'mobile'
        ? pendingCustomerFetcher
            .bind(c =>
              this.customerService.getUsersWithAccessToCustomerId(
                c.customerId,
                c.name,
                c.buidId
              )
            )
            .getAsyncEntity()
        : undefined;

    return {
      isMobile: device.screenSize === 'mobile',
      pendingUserAccess: userAccessFetcher,
      buids: buidFetcher
        .map(buids => buids.filter(b => hasAccessToBuid(getSession(), b) || b.buidId === responsibleBuid?.buidId))
        .getAsyncEntity(),
      customerManager: pendingCustomerFetcher
        .map2(buidFetcher, (customer, buids) => ({
          ...customer,
          buid: buids.find(b => b.buidId === customer.buidId)
        }))
        .getAsyncEntity()
    };
  };
}
