import { customElement } from 'aurelia-templating';
import { bindable, observable, autoinject } from 'aurelia-framework';
import './customerConnectedUsers.css';
import { BaseViewModel } from '../../../../common';
import { getLogger } from 'aurelia-logging';
import { CustomerService, UserService } from '../../../../../services';
import {
  IAsyncEntity,
  IAsyncEntityManager,
  createFetchedEntity
} from '../../../../../types';
import { IUser } from '../../../../../interfaces';
import {
  isNone,
  distinct,
  removeNoneFromArray,
  debounceRAF,
  orderByPredicate
} from '../../../../../utility';

interface IPendingUsersAccess {
  new: number;
  removed: number;
  current: IPendingUserWithAccess[];
}

interface IPendingUserWithAccess extends IUser {
  hasAccess: boolean;
  added: boolean;
  removed: boolean;
}

interface IUserWithAccess extends IUser {
  hasAccess: boolean;
}

interface IDefaultState {
  users: IAsyncEntity<IUserWithAccess[]>;
  access: IAsyncEntity<IPendingUsersAccess>;
}

type ICustomerConnectedUsersState = IDefaultState;

@autoinject()
@customElement('customer-connected-users')
export class CustomerConnectedUsers extends BaseViewModel<
  ICustomerConnectedUsersState
> {
  @bindable({ changeHandler: 'reattachmapstate' }) customerId:
    | number
    | undefined;
  @bindable({ changeHandler: 'reattachmapstate' }) pendingCustomerName:
    | string
    | undefined;
  @bindable({ changeHandler: 'reattachmapstate' }) pendingCustomerBuidId:
    | number
    | undefined;

  @observable({ changeHandler: 'reattachmapstate' })
  selectedTab: 'default' | 'access' = 'access';

  constructor(
    private customerService: CustomerService,
    private userService: UserService
  ) {
    super(getLogger('customer-connected-users'));
  }

  bind() {
    this.reattachmapstate();
  }

  reattachmapstate = debounceRAF(() => this.attachMapState(this.mapState));

  getDefaultUsers = (customerId: number | undefined) => {
    if (customerId === undefined) {
      return IAsyncEntityManager.Create(createFetchedEntity([]));
    }

    const defaultUsersFetcher = this.customerService.getUsersWithDefaultCustomerId(
      customerId
    );
    const userIdsFetcher = defaultUsersFetcher.map(users =>
      users.map(user => user.userId)
    );
    const tokensFetcher = defaultUsersFetcher.map(users =>
      users.map(user => user.token)
    );
    const usersFetcher = userIdsFetcher.bindWithDep(
      tokensFetcher,
      this.userService.getUsers
    );

    const users = usersFetcher
      .map2(defaultUsersFetcher, (users, access) =>
        users.map<IUserWithAccess>(u => ({
          ...u,
          hasAccess: !isNone(
            access.find(a => a.userId === u.userId && a.hasAccess)
          )
        }))
      )
      .map(users => orderByPredicate(users, user => user.name, 'asc'));

    return users;
  };

  mapDefaultState = (): IDefaultState => {
    const userAccessFetcher = this.customerService.getUsersWithAccessToCustomerId(
      this.customerId,
      this.pendingCustomerName,
      this.pendingCustomerBuidId
    );

    const userIdsAccessFetcher = userAccessFetcher.map(access =>
      distinct([
        ...access.current.map(c => c.userId),
        ...access.new.map(c => c.userId)
      ])
    );
    const tokensAccessFetcher = userAccessFetcher.map(access =>
      distinct([
        ...access.current.map(c => c.token),
        ...access.new.map(n => n.token)
      ])
    );

    const usersAccessFetcher = userIdsAccessFetcher.bindWithDep(
      tokensAccessFetcher,
      this.userService.getUsers
    );
    const createUserWithAccess = (
      userId: number,
      hasAccess: boolean,
      users: IUser[],
      addedUserIds: number[],
      removedUserIds: number[]
    ): IPendingUserWithAccess | undefined => {
      const user = users.find(u => u.userId === userId);
      if (!user) return undefined;
      return {
        ...user,
        hasAccess,
        added: addedUserIds.some(id => id === userId),
        removed: removedUserIds.some(id => id === userId)
      };
    };
    const usersAccess = userAccessFetcher.map2(
      usersAccessFetcher,
      (access, users): IPendingUsersAccess => {
        const removedUserIds = access.removed;
        const addedUserIds = access.new.map(n => n.userId);
        const userAccess = [...access.current, ...access.new];
        const current = orderByPredicate(
          removeNoneFromArray(
            userAccess.map(c =>
              createUserWithAccess(
                c.userId,
                c.hasAccess,
                users,
                addedUserIds,
                removedUserIds
              )
            )
          ),
          u => u.name,
          'asc'
        );
        return {
          current: current,
          new: access.new.length,
          removed: access.removed.length
        };
      }
    );

    return {
      users: this.getDefaultUsers(this.customerId).getAsyncEntity(),
      access: usersAccess.getAsyncEntity()
    };
  };

  mapState = () => this.mapDefaultState();
}
