import { ICustomerManagerDetails } from '../../../../interfaces';
import {
  orderByPredicate,
  lowerCaseAndTrimString,
  orderBy
} from '../../../../utility';

export const sortDataset = (
  items: ICustomerManagerDetails[],
  property: string,
  direction: string
) => {
  if (!items || !property || !direction) return items;

  switch (property) {
    case 'id':
      return orderByPredicate(items, item => item.customerId, direction);

    case 'name':
      return orderByPredicate(
        items,
        item => lowerCaseAndTrimString(item.name),
        direction
      );

    case 'buid':
      return orderByPredicate(
        items,
        item => (item.buid ? lowerCaseAndTrimString(item.buid.name) : ''),
        direction
      );

    case 'siteCount':
      return orderByPredicate(
        items,
        item => item.connectedSites && item.connectedSites.length,
        direction
      );

    case 'description':
      return orderByPredicate(
        items,
        item => lowerCaseAndTrimString(item.description),
        direction
      );

    case 'soldto':
      return orderByPredicate(
        items,
        item => lowerCaseAndTrimString(item.soldTo),
        direction
      );
  }

  return orderBy(items, property, direction);
};
