import { RouterConfiguration, Router } from 'aurelia-router';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { routeNames } from '../../config';
import './customermanager.css';

@autoinject()
export class CustomerManager {
  router: Router;

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([
      {
        route: '',
        name: routeNames.customermanagerlist,
        moduleId: PLATFORM.moduleName(
          './modules/customermanagerlist/customermanagerlist'
        ),
        title: 'UI_Titles_Customermanager'
      },
      {
        route: 'create',
        name: routeNames.customermanagercreate,
        moduleId: PLATFORM.moduleName(
          './modules/customermanagercreate/customermanagercreate'
        ),
        title: 'Create customer'
      },
      {
        route: 'details/:customerId/:tab?',
        name: routeNames.customermanagerdetails,
        moduleId: PLATFORM.moduleName(
          './modules/customermanagerdetails/customermanagerdetails'
        ),
        title: 'UI_Titles_Customermanager'
      },
      {
        route: 'multi/:customerIds',
        name: routeNames.customermanagermultiedit,
        moduleId: PLATFORM.moduleName(
          './modules/customermanagermultiedit/customermanagermultiedit'
        ),
        title: 'UI_Titles_Customermanager'
      }
    ]);

    this.router = router;
  }
}
