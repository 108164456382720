import { BaseViewModel } from '../../../common';
import { getLogger } from 'aurelia-logging';
import { autoinject } from 'aurelia-framework';
import { rootState } from '../../../../reducers';
import { ensureNumber, isNone } from '../../../../utility';
import { Router } from 'aurelia-router';
import { routeNames } from '../../../../config';
import { ICustomerConnectedSite} from '../../../../interfaces';
import { IAsyncEntity} from '../../../../types';
import { CustomerService, BuidService } from '../../../../services';
import { ICustomerManagerDetails } from '../../../../interfaces';
import './customermanagerdetails.css';

type tabs = 'info' | 'info-edit' | 'users' | 'sites' | undefined;
type urlParams = { tab: tabs; customerId: string };
type stateParams = { tab: tabs; customerId: number };

interface ICustomerManagerDetailsState {
  showInfo: boolean;
  showUsers: boolean;
  showSites: boolean;
  customerId: number;
  showEditInfo: boolean;
  pendingBuidId: number | undefined;
  pendingCustomerName: string | undefined;
  isMobile: boolean;
  customerManager: IAsyncEntity<ICustomerManagerDetails>;
  menuIsOpenForEntity: any;
}

@autoinject()
export class CustomerManagerDetails extends BaseViewModel<
  ICustomerManagerDetailsState
> {
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private buidService: BuidService
  ) {
    super(getLogger('customermanagerdetails'));
  }

  activate({ tab, customerId }: urlParams) {
    this.attachMapState(
      this.mapState({ tab, customerId: ensureNumber(customerId) }),
      () => !this.deletedCustomer
    );
  }

  afterSaveOrCancel() {
    if (this.state.isMobile) this.router.navigateBack();
    else
      this.router.navigateToRoute(
        routeNames.customermanagerdetails,
        { customerId: this.state.customerId, tab: 'info' },
        { replace: true }
      );
  }

  changesCancelled = this.afterSaveOrCancel;
  changesSaved = this.afterSaveOrCancel;

  mapState = ({ tab, customerId }: stateParams) => ({
    device,
    customermanager,
    application
  }: rootState) => {
    const { pendingCustomerManager } = customermanager;
    const isMobile = device.screenSize === 'mobile';
    const isEditingInfo =
      tab === 'info-edit'; // || !isNone(pendingCustomerManager);
    const customerFetcher = this.customerService.getCustomerManagersById(
      customerId
    );
    const buidFetcher = this.buidService.getAllBuids();
    return {
      showEditInfo: isEditingInfo,
      menuIsOpenForEntity: application.menuIsOpenForEntity,
      showInfo: !isEditingInfo && (!isMobile || tab === 'info' || isNone(tab)),
      showSites: !isMobile || tab === 'sites',
      showUsers: !isMobile || tab === 'users',
      showTabBar: isMobile && tab !== 'info-edit',
      pendingBuidId: pendingCustomerManager
        ? pendingCustomerManager.buidId
        : undefined,
      pendingCustomerName: pendingCustomerManager
        ? pendingCustomerManager.name
        : undefined,
      customerId,
      isMobile,
      customerManager: customerFetcher
        .map2(buidFetcher, (customer, buids) => ({
          ...customer,
          buid: buids.find(b => b.buidId === customer.buidId)
        }))
        .getAsyncEntity()
    };
  };

  moveToTab = (tab: tabs) =>
    this.router.navigateToRoute(
      routeNames.customermanagerdetails,
      { customerId: this.state.customerId, tab },
      { replace: tab !== 'info-edit' || !this.state.isMobile }
    );

  confirm: 'deletion' | undefined;
  deletedCustomer = false;
  deleteError: string | undefined;
  deleteCustomer = async () => {
    try {
      await this.customerService.removeCustomersAsync(
        [this.state.customerId],
        () => {
          this.deletedCustomer = true;
          this.confirm = undefined;
          this.deleteError = undefined;
          this.router.navigateBack();
        }
      );
    } catch (err) {
      this.deleteError = err;
    }
  };

  toCustomerList = () => {
    this.router.navigateToRoute(routeNames.customermanagerlist);
  };

  shouldDisableDeleteButton(connectedSites: ICustomerConnectedSite[]){

    let defaultCustomers = this.customerService.getUsersWithDefaultCustomerId(this.state.customerId).getEntityOrUndefined();

    //if the customer has default customers or connected sites attached, disable delete button to prevent customer being deleted.
    if(connectedSites.length > 0 || (defaultCustomers && defaultCustomers.length > 0))  return true;
    
    return false;
   }
 } 

 

