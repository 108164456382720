import { BaseViewModel } from '../../../common';
import {
  ICustomerManager,
  IBuid,
  ICustomerManagerDetails
} from '../../../../interfaces';
import { IAsyncEntity } from '../../../../types';
import { getLogger } from 'aurelia-logging';
import { CustomerService, BuidService } from '../../../../services';
import { autoinject } from 'aurelia-framework';
import {
  parseStringAsIntList,
  hasAccessToBuidCurried
} from '../../../../utility';
import { getSession } from '../../../../config/sessionService';
import { rootState } from '../../../../reducers';
import { customerManagerChange } from '../../customermanagerReducer';
import { Router } from 'aurelia-router';
import './customermanagermultiedit.css';

type urlParams = { customerIds: string };

interface ICustomerManagerMultiEditState {
  customerIds: number[];
  customerManagers: IAsyncEntity<ICustomerManager[]>;
  buids: IAsyncEntity<IBuid[]>;
  pendingCustomerManager: IAsyncEntity<Partial<ICustomerManagerDetails>>;
}

@autoinject()
export class CustomerManagerMultiEdit extends BaseViewModel<
  ICustomerManagerMultiEditState
> {
  constructor(
    private customerService: CustomerService,
    private buidService: BuidService,
    private router: Router
  ) {
    super(getLogger('CustomermanagerMultiEdit'));
  }
  activate({ customerIds }: urlParams) {
    this.attachMapState(this.mapState(parseStringAsIntList(customerIds)));
  }

  dispatchCustomerManagerChange = (property: string, value: string | number) =>
    this.dispatch(customerManagerChange({ property, value }));

  dispatchCustomerManagerBuidChange = (buid: IBuid) =>
    this.dispatchCustomerManagerChange('buidId', buid.buidId);

  cancelChanges() {
    this.router.navigateBack();
  }

  async saveChanges(customerManager: Partial<ICustomerManagerDetails>) {
    await this.customerService.updateCustomersAsync(
      customerManager,
      this.state.customerIds
    );
    this.router.navigateBack();
  }

  mapState = (customerIds: number[]) => ({ customermanager }: rootState) => {
    const buidsFetcher = this.buidService.getAllBuids();

    return {
      customerIds,
      buids: buidsFetcher
        .map(buids => buids.filter(hasAccessToBuidCurried(getSession())))
        .getAsyncEntity(),
      pendingCustomerManager: buidsFetcher
        .map<Partial<ICustomerManagerDetails>>(buids =>
          customermanager.pendingCustomerManager
            ? {
                ...customermanager.pendingCustomerManager,
                buid: buids.find(
                  buid =>
                    (customermanager.pendingCustomerManager &&
                      buid.buidId ===
                        customermanager.pendingCustomerManager.buidId) ||
                    false
                )
              }
            : {}
        )
        .getAsyncEntity(),
      customerManagers: this.customerService
        .getCustomerManagersByIds(customerIds)
        .getAsyncEntity()
    };
  };
}
