import { customElement, bindable, autoinject } from 'aurelia-framework';
import { IAsyncEntity } from '../../../../../types';
import { ISite, ICustomerConnectedSite } from '../../../../../interfaces';
import { BaseViewModel } from '../../../../common';
import { getLogger } from 'aurelia-logging';
import './customerConnectedSites.css';

@autoinject()
@customElement('customer-connected-sites')
export class CustomerConnectedSites extends BaseViewModel<
  IAsyncEntity<ISite[]>
> {
  @bindable() connectedSites: ICustomerConnectedSite[] | undefined;
  @bindable() loading: boolean;
  constructor() {
    super(getLogger(CustomerConnectedSites.name));
  }
}
