import { BaseViewModel } from '../../../common';
import { getLogger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import {
  ICustomerManagerDetails,
  IBuid,
  ICustomerManager
} from '../../../../interfaces';
import { IAsyncEntity } from '../../../../types';
import { rootState } from '../../../../reducers';
import { BuidService } from '../../../../services';
import { hasAccessToBuidCurried } from '../../../../utility';
import { getSession } from '../../../../config/sessionService';
import { customerManagerChange } from '../../customermanagerReducer';
import { routeNames } from '../../../../config';
import './customermanagercreate.css';

interface ICustomerManagerCreateState {
  customerManager: IAsyncEntity<Partial<ICustomerManagerDetails>>;
  buids: IAsyncEntity<IBuid[]>;
  isMobile: boolean;
  pendingBuidId: number | undefined;
  pendingCustomerName: string | undefined;
}

@autoinject
export class CustomerManagerCreate extends BaseViewModel<
  ICustomerManagerCreateState
> {
  constructor(private router: Router, private buidService: BuidService) {
    super(getLogger('CustomerManagerCreate'));
  }

  activate() {
    this.attachMapState(this.mapState);
  }

  mapState = ({ customermanager, device }: rootState) => ({
    isMobile: device.screenSize === 'mobile',
    buids: this.buidService
      .getAllBuids()
      .map(buids => buids.filter(hasAccessToBuidCurried(getSession())))
      .getAsyncEntity(),
    customerManager: this.buidService
      .getAllBuids()
      .map<Partial<ICustomerManagerDetails>>(buids => ({
        ...customermanager.pendingCustomerManager,
        buid: buids.find(
          b =>
            (customermanager.pendingCustomerManager &&
              b.buidId === customermanager.pendingCustomerManager.buidId) ||
            false
        )
      }))
      .getAsyncEntity(),
    pendingBuidId: customermanager.pendingCustomerManager
      ? customermanager.pendingCustomerManager.buidId
      : undefined,
    pendingCustomerName: customermanager.pendingCustomerManager
      ? customermanager.pendingCustomerManager.name
      : undefined
  });

  dispatchCustomerManagerChange = (property: string, value: string) =>
    this.dispatch(customerManagerChange({ property, value }));

  changesCancelled() {
    this.router.navigateBack();
  }

  changesSaved({ customerId }: ICustomerManager) {    
    this.router.navigateToRoute(
      //routeNames.customerManagerDetailsHref(customerId)
       routeNames.customermanagerdetails,
       { customerId, tab: 'info' },
       { replace: true }
    );
  }
}
