import { BaseViewModel } from '../../../common';
import { defaultMemoize } from 'reselect';
import {
  lowerCaseAndTrimString,
  assureArray,
  isNone,
  isArray
} from '../../../../utility';
import {
  ICustomerManagerDetails,
  IBuid,
  ICustomerManager
} from '../../../../interfaces';
import { rootState } from '../../../../reducers';
import { IAsyncEntity } from '../../../../types';
import { routeNames } from '../../../../config';
import {
  filterCustomerManagers,
  sortCustomerList,
  toggleCheckedCustomerManagager
} from '../../customermanagerReducer';
import { getLogger } from 'aurelia-logging';
import { CustomerService, BuidService } from '../../../../services';
import { autoinject } from 'aurelia-framework';
import { sortDataset } from './operations';
import { Router } from 'aurelia-router';
import './customermanagerlist.css';

interface ICustomerManagerState {
  customerManagers: IAsyncEntity<ICustomerManagerDetails[]>;
  customerFilter: string;
  sortDirection: string;
  sortProperty: string;
  translatableSortProperty: string;
  isAllSelected: boolean;
  checkedCustomersIds: number[];
  isMobile: boolean;
  menuIsOpenForEntity: any;
}

const createCustomerManagerDetails = defaultMemoize(
  (customerManagers: ICustomerManager[], buids: IBuid[]) =>
    customerManagers.map<ICustomerManagerDetails>(cm => ({
      ...cm,
      buid: buids.find(b => b.buidId === cm.buidId)
    }))
);

const filterCustomers = defaultMemoize(
  (customerManagers: ICustomerManager[], customerFilter: string) =>
    customerManagers.filter(customerManagers =>
      lowerCaseAndTrimString(customerManagers.name).includes(lowerCaseAndTrimString(customerFilter)) 
      || lowerCaseAndTrimString(customerManagers.alias).includes(lowerCaseAndTrimString(customerFilter))  
      || lowerCaseAndTrimString(customerManagers.soldTo).includes(lowerCaseAndTrimString(customerFilter))  
    )
);

const sortCustomers = defaultMemoize(sortDataset);

const getSortPropertyAsLanguageText = (property: string) => {
  switch (property) {
    case 'id':
      return 'UI_CustomerManager_Id';
    case 'name':
      return 'UI_Name';
    case 'alias':
      return 'UI_CustomerManager_Alias';
    case 'buid':
      return 'UI_CustomerManager_Buid';
    case 'siteCount':
      return 'UI_CustomerManager_SiteCount';
    case 'description':
      return 'UI_Description';
    case 'soldto':
      return 'UI_CustomerManager_SoldTo';
    default:
      return '';
  }
};

@autoinject()
export class CustomerManagerList extends BaseViewModel<ICustomerManagerState> {
  constructor(
    private customerService: CustomerService,
    private buidService: BuidService,
    private router: Router
  ) {
    super(getLogger('CustomerManagerList'));
  }

  mapState = ({
    customermanager,
    device,
    application
  }: rootState): ICustomerManagerState => ({
    sortDirection: customermanager.sortDirection,
    sortProperty: customermanager.sortProperty,
    translatableSortProperty: getSortPropertyAsLanguageText(
      customermanager.sortProperty
    ),
    menuIsOpenForEntity: application.menuIsOpenForEntity,
    customerManagers: this.customerService
      .getAllCustomerManagers()
      .map(customers =>
        filterCustomers(customers, customermanager.customerFilter)
      )
      .map2(this.buidService.getAllBuids(), createCustomerManagerDetails)
      .map(customers =>
        sortCustomers(
          customers,
          customermanager.sortProperty,
          customermanager.sortDirection
        )
      )
      .getAsyncEntity(),
    customerFilter: customermanager.customerFilter,
    isAllSelected: this.customerService
      .getAllCustomerManagers()
      .map(cm => cm.length === customermanager.checkedCustomersIds.length)
      .getEntityOrDefault(false),
    checkedCustomersIds: customermanager.checkedCustomersIds,
    isMobile: device.screenSize === 'mobile'
  });

  activate() {
    this.attachMapState(this.mapState);
  }

  filterCustomerList = (value: string) =>
    this.dispatch(filterCustomerManagers(value));

  sort = (property: string) => this.dispatch(sortCustomerList(property));

  deleteError: string | undefined;
  deleteConfirmation = false;
  deleteSelectedCustomers = async () => {
    try {
      await this.customerService.removeCustomersAsync(
        this.state.checkedCustomersIds,
        () => {
          this.deleteConfirmation = false;
          this.deleteError = undefined;
        }
      );
    } catch (err) {
      this.deleteError = err;
    }
  };

  toggleSelectCustomerManager = (
    customerOrCustomers: ICustomerManagerDetails[] | ICustomerManagerDetails,
    action: 'add' | 'remove' | undefined
  ) => {
    if (
      isNone(customerOrCustomers) ||
      (isArray(customerOrCustomers) && customerOrCustomers.length === 0)
    )
      return;
    const ids = assureArray(customerOrCustomers).map(c => c.customerId);
    if (isNone(action)) {
      const firstId = ids[0];
      action = this.state.checkedCustomersIds.some(id => id === firstId)
        ? 'remove'
        : 'add';
    }
    this.dispatch(toggleCheckedCustomerManagager({ action, ids }));
  };

  addNewCustomer = () =>
    this.router.navigateToRoute(routeNames.customermanagercreate);

  getLink(item: ICustomerManager) {
    return `/customermanager/details/${item.customerId}`;
  }

  editExistingCustomer = (customermanager: ICustomerManager) =>
    this.router.navigateToRoute(routeNames.customermanagerdetails, {
      customerId: customermanager.customerId
    });

  editSelectedCustomers = () => {
    const { checkedCustomersIds } = this.state;
    if (checkedCustomersIds.length === 1)
      this.router.navigateToRoute(routeNames.customermanagerdetails, {
        customerId: checkedCustomersIds[0],
        tab: 'info-edit'
      });
    else
      this.router.navigateToRoute(routeNames.customermanagermultiedit, {
        customerIds: this.state.checkedCustomersIds
      });
  };
}
